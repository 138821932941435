@import url('https://fonts.googleapis.com/css2?family=Chelsea+Market&family=Metal+Mania&family=Sail&display=swap');
/* font-family: 'Chelsea Market', cursive;
font-family: 'Metal Mania', cursive;
font-family: 'Sail', cursive; */

body {
  background-color: navy;
  color: rgb(216	143	33);
  text-align: center;
  background-image: url('./images/otter-attack-bg-og.jpeg');
  background-color: rgb(25, 22, 22);
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.App h1 {
  font-family: 'Sail', cursive;
}

.App h2 {
  font-family: 'Metal Mania', cursive;
}

.App p {
  font-family: 'Chelsea Market', cursive;
}

.container {
  margin: 0 auto;
}

.opaque-canvas {
  background-color: rgba(0, 0, 0, 0.6);
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.title {
  margin-bottom: 30px;
}

.logo-type {
  width: 70%;
  margin-top: 20px;
}

.logo-mono {
  width: 20%;
  margin-top: 20px;
  display: block;
  margin: 0 auto;
}

.content {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cta {
  margin-bottom: 50px;
  order: 1;
  padding: 0 10px;
}

.btn {
  background-color: yellow;
  color: black;
  border: 3px solid rgb(216, 143, 33);
  font-weight: bold;
}

.ripple {
  background-position: center;
  transition: background 0.4s;
}

.ripple:hover {
  background-color: rgb(250, 230, 3);
  background-image: radial-gradient(circle, transparent 1%, rgb(228, 211, 21) 1%);
  background-position: center;
  background-size: 15000%;
}

.ripple:active {
  transition: background 0s;
  background-color: rgb(246, 235, 117);
  background-size: 100%;
}

.image img{
  border: 3px solid rgb(216, 143, 33);
  border-radius: 5px;
  max-width: 90%;
  height: auto;
  order: 0;
}

input {
  border: none;
  border-radius: 5px;
  background-color: rgb(27, 155, 241);
  color: black;
  padding: 10px 15px;
  margin: 0 10px 15px 10px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: black;
}

button {
  border: 3px solid white;
  border-radius: 5px;
  background-color: rgb(218, 203, 32);
  color: navy;
  padding: 10px 15px;
  font-weight: 600;
}

.mc__form {
  position: relative;
  width: auto;
}

.notification-status {
  font-size: 0.6em;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.social {
  display: flex;
  justify-content: space-evenly;
  max-width: 300px;
  margin: 0 auto 20px;
  font-size: 2.5em;
}

.social a {
  text-decoration: none;
  color: inherit;
  transition: color 0.4s;
}

.social a:hover {
  color:rgb(250, 230, 3);
}

.water {
  height: 80px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

.wave {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave 15s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 30s linear reverse infinite;
  opacity: 0.5;
}

.wave:nth-of-type(3) {
  bottom: 0;
  animation: wave 30s linear infinite;
  opacity: 0.5;
}

@keyframes wave {
    0% {transform: translateX(0);}
    50% {transform: translateX(-25%);}
    100% {transform: translateX(-50%);}
}



@media screen and (min-width: 750px) {

  .logo-type {
    width: 50%;
  }
  
  .logo-mono {
    width: 10%;
  }

  .content {
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    font-size: 1.1em;
  }
  
  .cta {
    margin-bottom: 50px;
    order: 0;
    max-width: 300px;
    margin-right: 10px;
  }
  
  .image img{
    border: 3px solid rgb(216,	143,	33);
    border-radius: 5px;
    height: auto;
    margin-left: 10px;
  }

}

@media screen and (min-width: 900px) {

  .logo-type {
    width: 500px;
  }
  
  .logo-mono {
    width: 120px;
  }

  .content {
    margin-bottom: 40px;
  }

  .cta {
    font-size: 1.2em;
  }

  .social {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1000px) {
  .opaque-canvas {
    margin: 20px auto;
    width: 800px;
  }
}

@media screen and (min-height: 1000px) {
  .logo-type {
    width: 60%;
  }

  .logo-mono {
    width: 25%;
  }
  .content {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .opaque-canvas {
    height: 100vh;
  }
}